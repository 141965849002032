import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/components/AdsBanner.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/components/Calendar.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/components/FearAndGreedIndex.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/components/FundRaisingTable.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/components/Heatmap.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/components/KlardaFeeds.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/components/KlardaPrediction.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/components/KlardaSearch.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/components/LearnAboutCrypto.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/components/MarketSnapshot.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/components/Notable.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/components/Partner.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/components/Signals.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/components/TopNews.tsx");
import(/* webpackMode: "eager" */ "/opt/build/repo/src/app/components/Trending.tsx")
"use client";

import { Popover, Skeleton, Typography } from "antd/lib";
import { ClockCircleFilled } from "@ant-design/icons";
import classNames from "classnames";
import { useCallback, useEffect, useRef, useState } from "react";
import { usePrice } from "@/context/PriceContext";
import dayjs from "dayjs";
import PriceChange from "./PriceChange";
import { useCurrencyContext } from "@/context/CurrencyContext";
import type { CurrencyIF } from "@/helpers";
import { formatThousands } from "@/helpers";
import useMyQuery from "@/hooks/useMyQuery";
import relativeTime from "dayjs/plugin/relativeTime";
import circleIcon from "@/asset/images/icon_circle.png";
import Image from "next/image";
import { useLanguageContext } from "@/context/LanguageContext";

dayjs.extend(relativeTime);

interface FeedItem {
  id: string;
  title: string;
  subTitle?: string;
  created_at: string;
  hashTags: string[];
  sentiment: "positive" | "negative" | "neutral";
  symbols: Array<{
    symbol: string;
    price: number;
    change: number;
  }>;
}

interface FeedResponse {
  success: boolean;
  data: {
    total: number;
    result: FeedItem[];
  };
}

const PAGE_SIZE = 10;

const FeedSkeleton = () => (
  <div className="px-4 py-2 rounded-xl border border-gray-200">
    <div className="flex flex-col gap-2">
      <Skeleton.Input style={{ width: "100%" }} active size="small" />
      <div className="flex justify-between">
        <div className="flex gap-2">
          <div className="flex gap-2 flex-col">
            <Skeleton.Input style={{ width: 200 }} active size="small" />
            <Skeleton.Input style={{ width: 150 }} active size="small" />
          </div>
        </div>
        <Skeleton.Input style={{ width: 80 }} active size="small" />
      </div>
    </div>
  </div>
);

const formatTime = (dateString: string) => dayjs(dateString).fromNow();

const formatDetailTime = (dateString: string) => {
  const date = dayjs(dateString);
  return date.fromNow();
};

const FeedPopoverContent = ({
  feed,
  currentCurrency,
  getToken,
}: {
  feed: FeedItem;
  currentCurrency: CurrencyIF;
  getToken: (symbol: string) => any;
}) => {
  const { language } = useLanguageContext();
  return (
    <div className="max-w-[300px]">
      <div className="flex flex-col gap-2">
        <div className="flex justify-between">
          <div className="flex flex-wrap gap-2 max-w-[80%]">
            {feed.hashTags.map((tag, idx) => (
              <Typography.Text
                key={idx}
                onClick={() => window.open(`/${language}/news/search?value=${tag}`, "_blank")}
                className="bg-[#EEF2FF] text-[#4B5DFF] px-3 py-1 rounded-full text-sm cursor-pointer hover:bg-[#DDE1FF] hover:text-[#3742FA] transition-colors duration-200"
              >
                #{tag}
              </Typography.Text>
            ))}
          </div>
          <Typography.Text
            type={feed.sentiment === "positive" ? "success" : feed.sentiment === "negative" ? "danger" : undefined}
            className="text-sm"
          >
            {feed.sentiment === "positive" && "↑ Positive"}
            {feed.sentiment === "negative" && "↓ Negative"}
            {feed.sentiment === "neutral" && "- Neutral"}
          </Typography.Text>
        </div>

        <Typography.Title level={5} className="!mb-1">
          {feed.title}
        </Typography.Title>

        {feed.subTitle && (
          <Typography.Text type="secondary" className="text-sm line-clamp-3" title={feed.subTitle}>
            {feed.subTitle}
          </Typography.Text>
        )}

        <div className="flex justify-between mt-2">
          <div className="flex flex-col gap-4">
            {feed.symbols.map((symbol, idx) => (
              <Typography.Text
                key={idx}
                className="cursor-pointer"
                onClick={() => window.open(`/${language}/markets/${symbol.symbol}`, "_blank")}
              >
                {symbol.symbol}: {formatThousands(symbol.price, currentCurrency)} (
                <PriceChange price={getToken(symbol.symbol)?.price_change?.change_1D?.percentage || 0} />)
              </Typography.Text>
            ))}
          </div>
          <Typography.Text className="text-gray-400 text-sm whitespace-nowrap">
            <ClockCircleFilled className="mr-1" /> {formatDetailTime(feed.created_at)}
          </Typography.Text>
        </div>
      </div>
    </div>
  );
};
export default function KlardaFeeds() {
  const { getToken } = usePrice();
  const { currentCurrency } = useCurrencyContext();
  const [page, setPage] = useState(1);
  const [allFeeds, setAllFeeds] = useState<FeedItem[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const isLoadingRef = useRef(false);
  const { language } = useLanguageContext();

  const { data, isLoading } = useMyQuery<FeedResponse>(
    {
      url: "v1/market-movement/market/news-feed",
      params: {
        page,
        limit: PAGE_SIZE,
      },
      includeParamsInQueryKey: true,
    },
    { queryKey: ["v1/market-movement/market/news-feed", page] },
  );

  useEffect(() => {
    if (data?.data.result) {
      setAllFeeds(prev => [...prev, ...data.data.result]);
      isLoadingRef.current = false;
    }
  }, [data]);

  const hasMore = allFeeds.length < (data?.data.total || 0);

  const onScroll = useCallback(() => {
    const container = containerRef.current;
    if (!container || isLoadingRef.current || !hasMore) return;

    const { scrollTop, scrollHeight, clientHeight } = container;
    if (scrollTop + clientHeight >= scrollHeight - 50) {
      isLoadingRef.current = true;
      setPage(prev => prev + 1);
    }
  }, [hasMore]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", onScroll);
      return () => container.removeEventListener("scroll", onScroll);
    }
  }, [onScroll]);

  return (
    <div className="bg-white rounded-xl">
      <Typography.Title level={5} className="!mb-4 border-b pb-2">
        KLARDA FEEDS
      </Typography.Title>
      <div ref={containerRef} className="space-y-4 max-h-[300px] overflow-y-auto custom-scrollbar">
        {!allFeeds.length && isLoading ? (
          <>
            <FeedSkeleton />
            <FeedSkeleton />
            <FeedSkeleton />
          </>
        ) : (
          <>
            {allFeeds.map(feed => (
              <Popover
                key={feed.id}
                content={<FeedPopoverContent feed={feed} currentCurrency={currentCurrency} getToken={getToken} />}
                placement="right"
                mouseEnterDelay={0.5}
              >
                <div
                  className={classNames(
                    "px-4 py-2 rounded-xl cursor-pointer",
                    feed.sentiment === "positive" && "border border-[#52c41a]",
                    feed.sentiment === "negative" && "border border-[#ff4d4f]",
                    feed.sentiment === "neutral" && "border border-gray-200",
                  )}
                >
                  <div className="flex flex-col gap-2">
                    <Typography.Text strong>
                      <Image
                        src={circleIcon}
                        alt="icon"
                        width={16}
                        height={16}
                        style={{ marginRight: 8, display: "inline-block", verticalAlign: "middle" }}
                      />
                      {feed.title}
                    </Typography.Text>
                    <div className="flex justify-between">
                      <div className="flex gap-2">
                        {!!feed.symbols?.length && (
                          <div className="flex gap-2 flex-col">
                            {feed.symbols.map((symbol, idx) => (
                              <Typography.Text
                                key={idx}
                                className="cursor-pointer"
                                onClick={() => window.open(`/${language}/markets/${symbol.symbol}`, "_blank")}
                              >
                                {symbol.symbol}: {formatThousands(symbol.price, currentCurrency)} (
                                <PriceChange
                                  price={getToken(symbol.symbol)?.price_change?.change_1D?.percentage || 0}
                                />
                                )
                              </Typography.Text>
                            ))}
                          </div>
                        )}

                        <Typography.Text
                          type={
                            feed.sentiment === "positive"
                              ? "success"
                              : feed.sentiment === "negative"
                              ? "danger"
                              : undefined
                          }
                          className="text-sm"
                        >
                          {feed.sentiment === "positive" && "↑ Positive"}
                          {feed.sentiment === "negative" && "↓ Negative"}
                          {feed.sentiment === "neutral" && "- Neutral"}
                        </Typography.Text>
                      </div>
                      <Typography.Text className="text-gray-400 text-sm">
                        <ClockCircleFilled /> {formatTime(feed.created_at)}
                      </Typography.Text>
                    </div>
                  </div>
                </div>
              </Popover>
            ))}
            {isLoading && <FeedSkeleton />}
            {!hasMore && allFeeds.length > 0 && (
              <div className="text-center py-2">
                <Typography.Text type="secondary">No more feeds</Typography.Text>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

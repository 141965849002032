"use client";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslations } from "next-intl";
import Skeleton from "antd/lib/skeleton";
import Tooltip from "antd/lib/tooltip";
import Avatar from "antd/lib/avatar";
import Typography from "antd/lib/typography";
import FilterOptions from "@/app/components/FilterOptions";
import Heading from "@/app/components/Heading";
import useAxiosQuery from "@/hooks/useMyQuery";
import { arrayReference, formatPercent, formatThousands, mapColorHeatmap } from "@/helpers";
import { CLOUDFRONT_URL } from "@/constant/apiPath";
import MyCard from "@/app/components/MyCard";
import TokenPrice from "@/app/components/TokenPrice";
import PriceChange from "@/app/components/PriceChange";
import { Button, Modal } from "antd/lib";
import KlardaBubbleIcon from "@/svg/KlardaBubbleIcon";
import { useRouter } from "next/navigation";
import { useCurrencyContext } from "@/context/CurrencyContext";
import { useLanguageContext } from "@/context/LanguageContext";
import { ExportOutlined } from "@ant-design/icons";

const objMaxPercent: { [key: string]: number } = {
  "1D": 3,
  "1W": 6,
  "1M": 15,
  "3M": 30,
  "6M": 45,
  "1Y": 60,
  YTD: 60,
};

function Heatmap() {
  const [selectedName, setSelectedName] = useState<string>("Top 50");
  const t = useTranslations("home");
  const { currentCurrency } = useCurrencyContext();
  const { language } = useLanguageContext();
  const router = useRouter();
  const { data: responseHeatmapNormalRaw, isLoading: isLoadingHeatmapNormal } = useAxiosQuery<
    (string | number)[][] | undefined
  >({
    url: `${CLOUDFRONT_URL}/v1/market-movement/landing-page/heat-map/heat-map/${selectedName}/1D/market_cap`,
    method: "get",
    params: { name: selectedName },
    includeParamsInQueryKey: true,
  });

  const responseHeatmapNormal = useMemo<(string | number)[][] | undefined>(
    () => (responseHeatmapNormalRaw ? responseHeatmapNormalRaw.slice(0, 20) : undefined),
    [responseHeatmapNormalRaw],
  );

  const handleOptionClick = useCallback((value: string) => setSelectedName(value), []);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const tokens = useMemo(() => 
    responseHeatmapNormal
      ?.map(item => item[item.length - 2])
      .join(','),
    [responseHeatmapNormal]
  );

  const bubbleUrl = useMemo(() => 
    `https://bubbles.klarda.com/?tokens=${tokens}`,
    [tokens]
  );

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const heatmapDom = useMemo(
    () => (
      <div className="flex flex-col gap-y-3 h-full">
        <div className="h-full">
          <div className="w-full text-center flex flex-wrap gap-1 headmap-wrapper">
            {responseHeatmapNormal?.map((item: (number | string | undefined)[], index) => {
              const bg = mapColorHeatmap((item?.[item?.length - 3] as number) || 0, objMaxPercent["1D"]).toString({
                format: "hex",
              });

              return (
                <Tooltip
                  key={index}
                  title={
                    <div className="flex flex-col text-left space-y-2">
                      <div className="flex items-center space-x-2">
                        <Avatar src={item[item.length - 1]} size={24} />
                        <Typography.Text className="text-white">{item[item.length - 2]}</Typography.Text>
                      </div>
                      <Typography.Text className="text-white">
                        {selectedName.includes("Protocols") ? "TVL" : "Price"}:{" "}
                        {formatThousands(item[item.length - 4] as number, currentCurrency)}
                      </Typography.Text>
                      <Typography.Text className="text-white">
                        1D: <b>{formatPercent(item[item.length - 3] as number)}%</b>
                      </Typography.Text>
                      <Typography.Text className="text-white">
                        {selectedName.includes("Protocols") ? "Revenue 1D" : "Market Cap"}:{" "}
                        {formatThousands(item[item.length - 5] as number, currentCurrency)}
                      </Typography.Text>
                    </div>
                  }
                >
                  <div
                    className="flex-grow rounded-lg lg:basis-1/6 w-[150px] cursor-pointer flex flex-col gap-y-2 p-2 justify-center items-center mycard-hover"
                    style={{ backgroundColor: bg }}
                    onClick={() => router.push(`/${language}/markets/${item[item.length - 2]}`)}
                  >
                    <div className="flex gap-1 items-center justify-center">
                      <Avatar src={item[item.length - 1]} size={24} />
                      <Typography.Text className="text-[14px] text-black" strong>
                        {item[item.length - 2]}
                      </Typography.Text>
                    </div>
                    <div className="w-full flex justify-center space-x-1">
                      <TokenPrice
                        className="text-sm font-normal"
                        symbol={item[item.length - 2] as string}
                        initPrice={item[item.length - 4] as number}
                      />
                      <PriceChange price={item?.[item?.length - 3] as number} />
                    </div>
                  </div>
                </Tooltip>
              );
            })}
          </div>
          <div className="mt-2 flex w-full items-center justify-end">
            <div className="flex w-full justify-end gap-1">
              {arrayReference.map((item, index) => (
                <div key={index} className="w-16 p-1 text-center rounded-sm" style={{ background: item.color }}>
                  <Typography.Text className="text-black">{item.value["1D"]}%</Typography.Text>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    ),
    [currentCurrency, language, responseHeatmapNormal, router, selectedName],
  );
  
  return (
    <MyCard className="h-full lg:col-span-8" title={<Heading title={t("Heatmaps")} icon={null} />}>
      <div className="mb-2 flex items-center gap-2">
        <FilterOptions onOptionClick={handleOptionClick} selectedName={selectedName} />
        <Button 
          onClick={handleOpenModal}
          icon={<KlardaBubbleIcon className="size-5" />} 
        />
        <Modal
          title={
            <div className="flex justify-between mr-4">
              <span>Klarda Bubbles</span>
              <Button 
                size="small"
                href={bubbleUrl}
                target="_blank"
                icon={<ExportOutlined />}
                title="Open in new tab"
              />
            </div>
          }
          open={isModalOpen}
          onCancel={handleCloseModal}
          footer={null}
          width="90vw"
          style={{ top: 20 }}
        >
          <iframe
            src={bubbleUrl}
            style={{ width: '100%', height: '80vh', border: 'none' }}
          />
        </Modal>
      </div>
      <div style={{ height: "calc(100% - 50px)", width: "100%" }}>
        <Skeleton active paragraph={{ rows: 12 }} loading={isLoadingHeatmapNormal}>
          {heatmapDom}
        </Skeleton>
      </div>
    </MyCard>
  );
}

export default React.memo(Heatmap);
